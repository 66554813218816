<!--
 * @Author: xr
 * @Date: 2022-02-16 14:43:52
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 09:20:36
 * @FilePath: \postStation-office\src\views\download.vue
-->
<template>
   <div class="main">
    <div class="main_box">
      <a-row type="flex" :gutter="20" justify="space-between" style="padding:0px 10px 10px;">
        <a-col :span="7" style="padding-left: 0px">
          <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
          <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
        </a-col>
        <a-col :span="17">
          <a-row style="padding-top:2px;min-height:68vh;" class="sub-component-background-color">
          <a-empty v-if="downloadList.length===0" class="empty-data"/>
            <a-row v-else class="item" v-for="item in downloadList" :key="item.id">
                <div @click="download(item.id)">
                  <span class="title">{{item.title}}</span>
                  <span style="color:#666;float:right;">{{item.time}}</span>
                  <p>...[详细信息]</p>
                </div>
            </a-row>
          </a-row>
           <a-row type="flex" justify="center">
              <a-pagination v-show="downloadList.length" v-model="pageNo" :total="total" @change="onChange" style=" margin:20px 40px 10px 0px"/>
            </a-row>
        </a-col>
      </a-row>
      <!-- 友情链接 -->
      <link-box />
    </div>
   </div>
</template>

<script>
import linkBox from '@comp/layout/link'
import FindDemand from '@comp/findDemand'
import { getDownloadList, getDownloadById } from '@/api/down'
import { getDict } from '@/api/index'
import { Page } from '@/mixins/page'

export default {
  name: 'Download',
  mixins: [Page],
  components: {
    linkBox,
    FindDemand
  },
  data() {
    return {
      downloadList: [],
      technicalProjectContent: []
    }
  },
  methods: {
    /**
     * @description:下载文件
     * @param {String} id
     */
    download(id) {
      const param = { id: id }
      getDownloadById(param).then(res => {
        if (res.code === 200) {
          this.$message.success('下载中！')
          const a = document.createElement('a')
          a.href = res.result.fileUrl
          a.download = res.result.fileUrl
          a.click()
          window.URL.revokeObjectURL(res.result.fileUrl)
        } else {
          this.$message.error('获取文件失败！')
        }
      })
    },
    /**
     * @description:获取列表
     */
    getList() {
      const param = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      getDownloadList(param).then(res => {
        this.downloadList = res.result ? res.result.records : []
        this.total = res.result ? res.result.total * 1 : 0
      })
    }

  },
  activated() {
    this.getList()
    // 获取项目、需求
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
  }
}
</script>

<style lang="scss" scoped>
.item{
  width: 100%;
  min-height: 60px;
  border: 1px dotted #999;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  padding: 10px 4px;
  &:hover{
    background: #F5F5F5;
  }
  &:nth-last-child(1){
    border-bottom: 1px dotted #999;
  }
  p{
    margin: 6px 0px 0px 30px;
    font-size: 12px;
    cursor: pointer;
    a{
      color: #009;
      font-family: "宋体";
    }
  }
  .title{
    color: #155E94;
    cursor: pointer;
    &:hover{
      color: #FF0000;
    }
  }
}

@import "../assets/css/newsInformation.css";
</style>
