/*
 * @Author: xr
 * @Date: 2022-02-28 17:02:22
 * @LastEditors: xr
 * @LastEditTime: 2022-03-10 09:47:24
 * @FilePath: \postStation-office\src\api\down.js
 */

import request from '../utils/request'

// 获取下载列表
export const getDownloadList = query => {
  return request({
    url: '/sys/website/DownloadCenterController/queryDownloadCenter',
    method: 'get',
    params: query
  })
}

// 下载ByID
export const getDownloadById = query => {
  return request({
    url: '/sys/website/DownloadCenterController/queryDownloadCenterById',
    method: 'post',
    data: query
  })
}

// 人才招聘
export const getTalentData = query => {
  return request({
    url: '/sys/website/recruitment/list',
    method: 'get',
    params: query
  })
}
// 通过id查人才招聘信息
export const getTalentDataId = query => {
  return request({
    url: '/sys/website/recruitment/detail',
    method: 'get',
    params: query
  })
}
